

export default {
   //PROJECT: "http://localhost/apinativa/",
   PROJECT: "https://www.solincosta.com/apinativa/",
   TRANSACTION_OK: 'TRANSACTION_OK',
   SUCCESSFUL_PROCESS_MESSAGE: 'Proceso Exitoso!',
   TRANSACTION_FAIL: 'TRANSACTION_FAIL',
   ERR_NETWORK: 'ERR_NETWORK',
   RESPONSE_STATUS_OK: 200,
   RESPONSE_STATUS_FAIL_SERVER: 500,
   RESPONSE_STATUS_NO_FOUND: 404,
   RESPONSE_ERROR_MESSAGE: 'ERROR EN LA RESPUESTA AXIOS',
   FRONTEND_EXCEPTION_MESSAGE: 'ERROR EN EL FRONTEND',
   TOAST_SUCCESS_MESSAGE: 'Mensaje de Confirmación',
   TOAST_INFO_MESSAGE: 'Mensaje de Información',
   TOAST_ALERT_MESSAGE: 'Mensaje de Alerta',
   TOAST_ERROR_MESSAGE: 'Mensaje de Error',
   RESPONSE_EMPTY: 'No se encontraron registros',
   SIZE_MAX_FILE: 1500,
   MEGA_BASE: 1024
}


