import { ToastSeverity } from "primevue/api";
import { app } from '../main';

const lifeTimeOK = 3000;
const lifeTimeERR = 3000;

function success(title, body) {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.SUCCESS,
    summary: title,
    detail: body,
    life: lifeTimeOK,
  });
}

function info(title, body) {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.INFO,
    summary: title,
    detail: body,
    life: lifeTimeOK,
  });
}

function warn(title, body) {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.WARN,
    summary: title,
    detail: body,
    life: lifeTimeERR,
  });
}

function error(title, body) {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.ERROR,
    summary: title,
    detail: body,
    life: lifeTimeERR,
  });
}


export default {
  hello,
  success,
  info,
  warn,
  error,
};

function hello() {
  alert("HELLO");
}


