import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";

//axios.defaults.baseURL = "http://localhost:8000/api/";
//axios.defaults.baseURL = "http://localhost/apinativa/model/";
axios.defaults.baseURL = "https://solincosta.com/apinativa/model";

import PrimeVue from "primevue/config";
import 'primeflex/primeflex.css';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DropDown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Galleria from 'primevue/galleria';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import Dialog from 'primevue/dialog';

import "primevue/resources/themes/saga-green/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import 'primeicons/primeicons.css';

import constantes from "./util/constantes";
import utilidades from "./util/utilidades";

const app = createApp(App).use(router);

app.config.globalProperties.APICONST = constantes;
app.config.globalProperties.UTILS = utilidades;

app.component("InputText", InputText);
app.component("ButtonPrime", Button);
app.component("DropDown", DropDown);
app.component("SkeletonLoading", Skeleton);
app.component("ProgressSpinner", ProgressSpinner);
app.component("TextArea", Textarea);
app.component("InputSwitch", InputSwitch);
app.component("CalendarDate", Calendar);
app.component("ToastPrime", Toast);
app.component("MessageText", Message);
app.component("DataTable", DataTable);
app.component("DialogModal", Dialog);
app.component("GalleriaMedia", Galleria);
app.component("ColumnBasic", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("RowBasic", Row);
app.use(PrimeVue);
app.use(ToastService);
app.use(router);
app.use(VueAxios, axios);


app.mount("#app");
export { app };

