<template>
  <div>
    <NavbarMain3Copy> </NavbarMain3Copy>

    <div class="container text-center mainreduce">
      <div class="row">
        <div class="col-12">
          <InputText
            id="username"
            class="allwidth"
            autocomplete="off"
            v-model="filterplan"
            placeholder="Digite código del plan de Mantenimiento"
            @keyup.enter="getplan"
          />
        </div>
      </div>

      <div class="row">
        <MessageText v-show="consultasinresultado" severity="warn" sticky>{{
          mensajesinresultado
        }}</MessageText>
      </div>
    </div>

    <OverlayCustom :visible="overlayVisible" @close="overlayVisible = false">
    </OverlayCustom>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarMain3Copy from "@/components/NavbarMain3Copy.vue";
import OverlayCustom from "@/components/OverlayCustom.vue";
//import TableSkeleton from "@/components/TableSkeleton.vue";

export default {
  name: "QrMantenimientos",
  data() {
    return {
      overlayVisible: false,

      consultasinresultado: false,
      mensajesinresultado: "",

      filterplan: "",
    };
  },

  methods: {
    showOverlay() {
      this.overlayVisible = true;

      setTimeout(() => {
        this.overlayVisible = false;
      }, 3000);
    },

    generarreporte: function (data) {
      var link =
        this.APICONST.PROJECT + "report/reportmtto.php?sticker=" + data;
      window.open(link, "_blank");
    },

    getplan: async function () {
      try {
        this.overlayVisible = true;
        let self = this;
        await this.axios
          .get(
            "planesmtto.php?dato=getmantenimientosxplan&codigoplan=" +
              this.filterplan
          )
          .then(function (response) {
            if (response.status === self.APICONST.RESPONSE_STATUS_OK) {
              if (response.data.length > 0) {
                self.consultasinresultado = false;
              } else {
                self.mensajesinresultado =
                  self.APICONST.TOAST_ALERT_MESSAGE +
                  ": " +
                  self.APICONST.RESPONSE_EMPTY;
                self.consultasinresultado = true;
              }
            } else {
              self.UTILS.error(
                self.APICONST.TOAST_ERROR_MESSAGE,
                "Error al computadores"
              );
            }
          })
          .catch((error) => {
            self.UTILS.error(
              self.APICONST.TOAST_ERROR_MESSAGE,
              self.APICONST.RESPONSE_ERROR_MESSAGE
            );
            self.UTILS.error(error.code, error.message);
          })
          .finally(() => {
            self.overlayVisible = false;

            if (!self.consultasinresultado) {
              var link =
                self.APICONST.PROJECT +
                "report/reportplan.php?codigoplan=" +
                self.filterplan;
              window.open(link, "_blank");
            }
          });
      } catch (e) {
        self.UTILS.warn(
          self.APICONST.TOAST_ALERT_MESSAGE,
          self.APICONST.FRONTEND_EXCEPTION_MESSAGE
        );
        alert("Exception Javascript: " + e);
        console.log(e);
      }
    },

    buscarComputadorxSticker() {
      this.showOverlay();
    },
  },

  components: {
    NavbarMain3Copy,
    OverlayCustom,
    //TableSkeleton,
  },

  created: function () {
    //this.showOverlay();
  },

  mounted() {},
};
</script>

<style>
.mainreduce {
  transform: scale(0.8);
}

.allwidth {
  width: 100%;
}
.container-computador {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pantalla {
  width: 250px;
  height: 100px;
  border: 5px solid;
  border-color: green;
  border-radius: 10px;
  position: relative;
}
.basevertical {
  width: 80px;
  height: 40px;
  border: 5px solid;
  border-color: green;
  position: relative;
  top: -5px;
}

.basehorizontal {
  width: 250px;
  height: 20px;
  border: 5px solid;
  border-color: green;
  position: relative;
  top: -10px;
}
.container-cpu {
  width: 80px;
  height: 100px;
  position: relative;
  margin-left: 10px;
  perspective: 800px;
}
</style>
