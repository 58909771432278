import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import QrMantenimientos from '../views/QrMantenimientos.vue'
import PlanMantenimiento from '../views/PlanMantenimiento.vue'
import RegistroMantenimiento from '../views/RegistroMantenimiento.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/consultamantenimiento',
    name: 'consultamantenimiento',
    component: QrMantenimientos
  },

  {
    path: '/nuevomantenimiento',
    name: 'nuevomantenimiento',
    component: RegistroMantenimiento
  },
  {
    path: '/consultaplan',
    name: 'consultaplan',
    component: PlanMantenimiento
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
