<template>
  <div class="fila fila-a">
    <div class="columna">
      <h2>Cámaras de Seguridad</h2>
      <div class="parragrafcontent">
        <p class="lead">
          Invierta en seguridad, acceso desde cualquier lugar a través de su
          computador o dispositivo móvil
        </p>
        <a
          class="btn-2 btn-2a"
          href=""
          role="button"
          data-toggle="modal"
          data-target="#myModal"
          >Ver detalles</a
        >
      </div>
    </div>
    <div class="columna">
      <img
        class="img-fluid imgcontent"
        src="@/assets/camaras.jpg"
        alt="cPanel"
        style="border: 0 !important"
      />
    </div>
  </div>

  <div class="fila fila-b">
    <div class="columna">
      <img
        class="img-fluid imgcontent"
        src="@/assets/support.png"
        alt="Webmail Pro"
        style="border: 0 !important"
      />
    </div>
    <div class="columna">
      <h2>Soporte Tecnológico</h2>
      <div class="parragrafcontent">
        <p class="lead">
          Nuestro servicio de de mesa de ayuda donde podrás recibir asesoría y
          soporte a pequeñas empresas y particulares
        </p>
        <a
          class="btn-2 btn-2a"
          href=""
          role="button"
          data-toggle="modal"
          data-target="#myModalSupport"
          >Ver detalles</a
        >
      </div>
    </div>
  </div>

  <div class="fila fila-a">
    <div class="columna">
      <h2>Desarrollo de páginas web</h2>
      <div class="parragrafcontent">
        <p class="lead">
          La transformación digital pasó de ser una necesidad a una obligación,
          hazte conocer en el mercado web
        </p>
        <a
          class="btn-2 btn-2a"
          href=""
          role="button"
          data-toggle="modal"
          data-target="#myModal"
          >Ver detalles</a
        >
      </div>
    </div>
    <div class="columna">
      <img
        class="img-fluid imgcontent"
        src="@/assets/webdesign.png"
        alt="cPanel"
        style="border: 0 !important"
      />
    </div>
  </div>

  <div class="fila fila-b">
    <div class="columna">
      <img
        class="img-fluid imgcontent"
        src="@/assets/pos.png"
        alt="Webmail Pro"
        style="border: 0 !important"
      />
    </div>
    <div class="columna">
      <h2>Sistemas de Información</h2>
      <div class="parragrafcontent">
        <p class="lead">
          Implementa sistemas de facturación, inventarios y logisticos para la
          correcta administración de recursos en tu negocio o empresa
        </p>
        <a
          class="btn-2 btn-2a"
          href=""
          role="button"
          data-toggle="modal"
          data-target="#myModalSupport"
          >Ver detalles</a
        >
      </div>
    </div>
  </div>

  <div class="fila-a" id="planes">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h3 class="tittleplans">Nuestros Planes</h3>
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <h2 class="subtittleplans">Pymes Basic</h2>
                <p class="textplans">
                  Ideal para empresas pequeñas con maximo: 1 servidor, 4 puestos
                  de trabajo y 1 sistema de cámaras de seguridad
                </p>
                <a
                  href=""
                  class="btn-1 btn-1a parragrafcontent"
                  role="button"
                  data-toggle="modal"
                  data-target="#myModalPymesBasic"
                  >Ver más</a
                >
              </div>
              <div class="col-md-4">
                <h2 class="subtittleplans">Pymes Emprendedor</h2>
                <p class="textplans">
                  Ideal para empresas pequeñas con máximo: 1 servidor, 10
                  puestos de trabajo y 2 sistemas de cámaras de seguridad
                </p>
                <a
                  href=""
                  class="btn-1 btn-1b parragrafcontent"
                  role="button"
                  data-toggle="modal"
                  data-target="#myModalPymesEmprendedor"
                  >Ver más</a
                >
              </div>
              <div class="col-md-4">
                <h2 class="subtittleplans">Pymes PRO</h2>
                <p class="textplans">
                  Ideal para empresas pequeñas con máximo: 1 servidor, 15
                  puestos de trabajo y 4 sistemas de cámaras de seguridad.
                </p>
                <a
                  href=""
                  class="btn-1 btn-1c parragrafcontent"
                  role="button"
                  data-toggle="modal"
                  data-target="#myModalPymesPro"
                  >Ver más</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fila fila-c">
    <div class="col-lg-6 offset-lg-0">
      <p class="text-muted text-center text-wrap textaboutus">
        Soy Tecnico en Mantenimiento de Hardware del año 2010, Ingeniero de Sistemas del año 2016 especializado en Gestión de Tecnologías de la Información en el 2021.
        Tengo 13 años de experiencia en el sector TIC.
        Me gusta la innovación continua y tratar siempre de buscar las mejores soluciones a problemas apoyándome en las mejores herramientas.
      </p>
    </div>
  </div>

  <div class="fila fila-d">
    <div class="col-lg-8 offset-lg-0">
      <p class="text-muted text-center textfooter">
        SOLUCIONES INFORMATICAS DE LA COSTA - Telefono: 304 4400440 o
                proyectos@solincosta.com
      </p>
    </div>
  </div>



</template>

<script>
export default {
  name: "ContentCameras",

  data() {
    return {};
  },

  methods: {},

  created: function () {
    /*
    addEventListener("DOMContentLoaded", () => {
      const btn_menu = document.querySelector(".btn_menu");

      if (btn_menu) {
        btn_menu.addEventListener("click", () => {
          const menu_items = document.querySelector(".menu_items");
          menu_items.classList.toggle("show");
        });
      }
    });
*/
  },
};
</script>

<style>
.tittleplans {
  font-size: 28px;
  font-weight: bold;
  padding-top: 20px;
}

.subtittleplans {
  font-size: 10px;
  font-weight: normal;
}

.textplans {
  font-size: 14px;
}

.textaboutus {
  font-size: 13px;
  padding-top: 20px;
}

.textfooter {
  font-size: 13px;
  color: #fff !important;
  padding-top: 10px;
}

h2 {
  font-weight: bold;
  font-size: 130% !important;
}

.fila {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.fila-a {
  background-color: #f2f2f2;
}

.fila-b {
  background-color: #d9d9d9;
}

.fila-c {
  background-color: #d9d9d9;
}

.fila-d {
  align-items: center;
  background-color: #7e7e7e;
}


.footer{
  background-color: #7e7e7e;
  font-size: 8px;
}

.footer p{
  font-size: 13px;
  color: #fff;
}

.columna {
  text-align: center;
  flex-basis: 50%;
  margin: 5px;
  min-width: 0;
}

.imgcontent {
  transform: scale(0.6);
}

.parragrafcontent {
  transform: scale(0.8);
}

@media (max-width: 767px) {
  .fila {
    flex-direction: column;
  }
}

.contenido-a {
  padding: 50px 0;
}
.contenido-b {
  padding: 50px 0;
  background-color: #d9d9d9;
}
.contenido-a img,
.contenido-b img {
  border: solid 1px #ccc;
}
.separador {
  border: 0;
  height: 1px;
  width: 70%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}
.btn-1 {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 80px;
  display: inline-block;
  margin: 15px 30px;
  font-weight: bold;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-1:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-2 {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 80px;
  display: inline-block;
  font-weight: bold;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-2:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
/* .btn-1a {border: 3px solid #ecbb17; color: #ecbb17} */

.img-content-a {
  align-items: end;
}

.bordeado {
  border: 10px solid #6a1a23;
}

.bordeadointerno {
  padding-left: 40px;
  border: 10px solid #000000;
}

.btn-1a {
  border: 3px solid #6a1a23;
  color: #6a1a23;
  text-decoration: none;
}
.btn-1b {
  border: 3px solid #6a1a23;
  color: #6a1a23;
  text-decoration: none;
}
.btn-1c {
  border: 3px solid #6a1a23;
  color: #6a1a23;
  text-decoration: none;
}
.btn-1d {
  border: 3px solid #fff;
  color: #fff;
}
.btn-2a {
  border: 3px solid #6a1a23;
  color: #6a1a23;
  margin-bottom: 25px;
  text-decoration: none;
}
.btn-1a:hover,
.btn-1a:active {
  color: #fff;
  background: #6a1a23;
}
.btn-1b:hover,
.btn-1b:active {
  color: #fff;
  background: #6a1a23;
}
.btn-1c:hover,
.btn-1c:active {
  color: #fff;
  background: #6a1a23;
}
.btn-1d:hover,
.btn-1d:active {
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.btn-2a:hover,
.btn-2a:active {
  color: #fff;
  background: #6a1a23;
}
a.btn-1a:hover,
a.btn-1a:active,
a.btn-1b:hover,
a.btn-1b:active,
a.btn-1c:hover,
a.btn-1c:active,
a.btn-2a:hover,
a.btn-2a:active {
  text-decoration: none;
}
a.btn-1d:hover,
a.btn-1d:active {
  text-decoration: none;
  color: #333;
}

</style>
