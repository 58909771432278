<template>
    <div v-if="visible" class="overlay">
    <div class="overlay-content">
      <div class="row">
        <div class="col-12 text-center">
          <ProgressSpinner />
        </div>
        <div class="col-12 text-center">
          <h2>Cargando Información...</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue';

export default {
  name: 'OverlayCustom',

  props: {
    visible: Boolean
  },

  setup(props, { emit }) {
    const overlayRef = ref(null);

    const closeOverlay = () => {
      emit('close');
    };

    return { overlayRef, closeOverlay };
  }
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>