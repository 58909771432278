<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <NavbarMain3Copy />
    <div >
      <img src="@/assets/workspace.jpg" class="img-fluid imagen" alt="..." />

      <!-- 
      <GalleriaMedia
        :value="images"
        :responsiveOptions="responsiveOptions"
        :numVisible="5"
        containerStyle="max-width: 640px"
      >
        <template #item="slotProps">
          <img
            :src="slotProps.item.itemImageSrc"
            :alt="slotProps.item.alt"
            style="width: 100%"
          />
        </template>
        <template #thumbnail="slotProps">
          <img
            :src="slotProps.item.thumbnailImageSrc"
            :alt="slotProps.item.alt"
          />
        </template>
      </GalleriaMedia>
-->
      
    </div>
    <ContentCameras>

    </ContentCameras>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import NavbarMain3Copy from "@/components/NavbarMain3Copy.vue";
import ContentCameras from "@/components/ContentCameras.vue";
import { PhotoService } from "@/service/PhotoService";

export default {
  name: "HomeView",
  data() {
    return {
      images: null,
      responsiveOptions: [
        {
          breakpoint: "991px",
          numVisible: 4,
        },
        {
          breakpoint: "767px",
          numVisible: 3,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
        },
      ],
    };
  },
  components: {
    NavbarMain3Copy,
    ContentCameras
  },

  mounted() {
    PhotoService.getImages().then((data) => (this.images = data));
  },
};
</script>

<style>
.imagen {
  width: 1920px !important; 
}
</style>
