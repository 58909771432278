<template>
  <div>
    <NavbarMain3Copy> </NavbarMain3Copy>

    <div class="container text-center mainreduce">
      <div class="row">
        <div class="col-12">
          <ButtonPrime
            icon="pi pi-plus"
            label="Nuevo mantenimiento"
            @click="nuevoequipo"
          />
        </div>
      </div>

      <div class="row">
        <MessageText v-show="consultasinresultado" severity="warn" sticky>{{
          mensajesinresultado
        }}</MessageText>
      </div>

      <!-- 
        <div class="row">
          <TableSkeleton
            :visible="overlayVisible"
            @close="overlayVisible = false"
          >
          </TableSkeleton>
        </div>
        -->
    </div>

    <OverlayCustom :visible="overlayVisible" @close="overlayVisible = false">
    </OverlayCustom>

    <DialogModal
      header="Header"
      :visible="showmodalinfoequipo"
      :modal="true"
      :style="{ width: '75vw' }"
      class="ochenta"
      @update:visible="ocultarDialogInfoEquipo"
    >
      <template #header>
        <h5>Información del equipo</h5>
      </template>

      <form>
        <div class="p-fluid grid formgrid">
          <div class="field col-12 md:col-3">
            <label for="serial">Serial</label>
            <InputText
              id="serial"
              placeholder="Serial"
              v-model="mantenimiento.computador.serial"
              autocomplete="off"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="placa">Placa</label>
            <InputText
              id="placa"
              placeholder="Placa"
              v-model="mantenimiento.computador.placa"
              autocomplete="off"
            />
          </div>

          <div class="field col-12 md:col-3">
            <div style="padding-bottom: 10px">Estado equipo</div>
            <!--
            <InputText
              id="activo"
              placeholder="Activo"
              v-model="mantenimiento.computador.activo"
            />
            -->
            <InputSwitch v-model="mantenimiento.computador.activo" />
          </div>

          <div class="field col-12 md:col-3">
            <div style="padding-bottom: 10px">Estado Garantía</div>
            <InputSwitch
              id="garantia"
              v-model="mantenimiento.computador.garantia"
            />
            <!--
            <InputText
              id="garantia"
              placeholder="Garantía"
              v-model="mantenimiento.computador.garantia"
            />
            -->
          </div>

          <div class="field col-12 md:col-6">
            <label for="marca">Marca</label>
            <InputText
              id="marca"
              placeholder="Marca"
              v-model="mantenimiento.computador.marca"
              autocomplete="off"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="modelo">Modelo</label>
            <InputText
              id="modelo"
              placeholder="Modelo"
              v-model="mantenimiento.computador.modelo"
              autocomplete="off"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="procesador">Procesador</label>
            <InputText
              id="procesador"
              placeholder="Procesador"
              v-model="mantenimiento.computador.procesador"
              autocomplete="off"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="discoduro">Disco duro</label>
            <InputText
              id="discoduro"
              placeholder="Discoduro"
              v-model="mantenimiento.computador.discoduro"
              autocomplete="off"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="ram">Ram</label>
            <InputText
              id="ram"
              placeholder="Ram"
              v-model="mantenimiento.computador.ram"
              autocomplete="off"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="sticker">Sticker Inventario</label>
            <InputText
              id="sticker"
              placeholder="Sticker"
              v-model="mantenimiento.computador.sticker"
              autocomplete="off"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label for="tipopc">Tipo de computador</label>
            <DropDown
              v-model="mantenimiento.computador.tipocomputador"
              :options="tiposcomputadores"
              optionLabel="detalle"
              placeholder="Seleccionar un tipo de computador"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="tipopropiedad">Tipo de propiedad</label>

            <DropDown
              v-model="mantenimiento.computador.tipopropiedad"
              :options="tipospropiedades"
              optionLabel="detalle"
              placeholder="Seleccionar una propiedad"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label for="fechamantenimiento">Fecha Mantenimiento</label>
            <CalendarDate
              id="fechamantenimiento"
              placeholder="Fecha Mantenimiento"
              v-model="mantenimiento.fecha"
              showIcon
              dateFormat="yy-mm-dd"
              autocomplete="off"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="tecnico">Responsable Mantenimiento</label>

            <DropDown
              v-model="mantenimiento.tecnico"
              :options="tecnicos"
              optionLabel="nombre"
              placeholder="Seleccionar un tecnico"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="stickermtto">Sticker Mtto</label>
            <InputText
              id="stickermtto"
              placeholder="Sticker Mtto"
              v-model="mantenimiento.sticker"
              autocomplete="off"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="calificacion">Evaluación</label>
            <InputText
              id="calificacion"
              placeholder="Calificación"
              v-model="mantenimiento.calificacion"
              autocomplete="off"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="funcionario">Responsable del Equipo</label>

            <DropDown
              v-model="mantenimiento.funcionario"
              :options="responsables"
              optionLabel="nombre"
              placeholder="Seleccionar un funcionario"
            />
          </div>

          <div class="field col-12">
            <label for="observaciones">Observaciones</label>
            <TextArea
              id="observaciones"
              v-model="mantenimiento.observaciones"
              rows="4"
              cols="60"
              placeholder="Observaciones"
              autocomplete="off"
            />
          </div>

          <div class="field col-12 signature-container">
            <label class="signature-label">Firme Aquí ⬇️</label>

            <VueSignaturePad
              id="signature"
              ref="signaturePad"
              :options="options"
            />

            <ButtonPrime
              @click="undoSignature"
              label="Corregir Firma"
              icon="pi pi-eraser"
              severity="secondary"
              aria-label="Bookmark"
            />
          </div>

          <div class="field col-12">
            <input
              type="file"
              class="form-control-file"
              id="mtto_photobefore"
              ref="mtto_photobefore"
              @change="onFileChangeBefore"
            />
            <label for="mtto_photobefore">(Foto antes) </label>
          </div>

          <div class="field col-12">
            <input
              type="file"
              class="form-control-file"
              id="mtto_photoafter"
              ref="mtto_photoafter"
              @change="onFileChangeAfter"
            />
            <label for="mtto_photoafter">(Foto despues) </label>
          </div>

          <div class="field col-12">
            <input
              type="file"
              class="form-control-file"
              id="mtto_photosticker"
              ref="mtto_photosticker"
              @change="onFileChangeSticker"
            />
            <label for="mtto_photosticker">(Foto Sticker) </label>
          </div>

          <div class="field col-12">
            <input
              type="file"
              class="form-control-file"
              id="mtto_photoadicional"
              ref="mtto_photoadicional"
              @change="onFileChangeAdicional"
            />
            <label for="mtto_photoadicional">(Foto Adicional) </label>
          </div>

          <div class="field col-12" v-show="showMessage">
            <MessageText :severity="typemessage">{{
              messageReturn
            }}</MessageText>
          </div>

          <div class="field col-12 md:col-3">
            <ButtonPrime
              icon="pi pi-save"
              severity="success"
              aria-label="Cancel"
              label="Guardar"
              @click="guardarMantenimiento()"
            />
          </div>
        </div>
      </form>
      <template #footer> </template>
    </DialogModal>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarMain3Copy from "@/components/NavbarMain3Copy.vue";
import OverlayCustom from "@/components/OverlayCustom.vue";
//import TableSkeleton from "@/components/TableSkeleton.vue";
import { VueSignaturePad } from "vue-signature-pad";

export default {
  name: "RegistroMantenimiento",
  data() {
    return {
      firma: null,
      signaturePad: null,
      typemessage: "warn",
      messageReturn: "Warning Message Content",
      showMessage: false,
      overlayVisible: false,
      computadoresloading: true,
      showmodalinfoequipo: false,
      computadores: [],
      consultasinresultado: false,
      mensajesinresultado: "",
      sololectura: true,

      tiposcomputadores: [],
      tecnicos: [],
      tipospropiedades: [],
      responsables: [],

      options: {
        penColor: "#191919",
      },
      mantenimiento: {
        id: 0,
        sticker: "",
        fecha: "",
        observaciones: "",
        calificacion: 0,
        tecnico: {
          id: 0,
          nombre: "",
          telefono: "",
          correo: "",
        },
        funcionario: {
          id: 0,
          nombre: "",
          cargo: "",
          correo: "",
          telefono: "",
          token: "",
          dependencia: {
            id: 0,
            nombre: "",
            empresa: {
              id: 0,
              nombre: "",
              digito: 0,
              telefono: "",
              telefonoalterno: "",
              correo: "",
              correoalterno: "",
            },
          },
        },
        computador: {
          id: 0,
          serial: "",
          placa: "",
          marca: "",
          modelo: "",
          procesador: "",
          discoduro: "",
          ram: "",
          sticker: "",
          activo: true,
          garantia: true,
          tipopropiedad: {
            id: 0,
            detalle: "",
          },
          tipocomputador: {
            id: 0,
            detalle: "",
          },
        },
        impresora: {
          id: 0,
          serial: "",
          placa: "",
          marca: "",
          modelo: "",
          sticker: "",
          activo: true,
          garantia: true,
          tipopropiedad: {
            id: 0,
            detalle: "",
          },
          tipoimpresora: {
            id: 0,
            detalle: "",
          },
        },

        fotoantes: null,
        sizefotoantes: 0,

        fotodespues: null,
        sizefotodespues: 0,

        fotosticker: null,
        sizefotosticker: 0,

        fotoadicional: null,
        sizefotoadicional: 0,
      },
    };
  },

  methods: {
    onFileChangeBefore(event) {
      this.mantenimiento.sizefotoantes = event.target.files[0].length;
      this.mantenimiento.fotoantes = event.target.files[0];
    },

    onFileChangeAfter(event) {
      this.mantenimiento.sizefotodespues = event.target.files[0].length;
      this.mantenimiento.fotodespues = event.target.files[0];
    },

    onFileChangeSticker(event) {
      this.mantenimiento.sizefotosticker = event.target.files[0].length;
      this.mantenimiento.fotosticker = event.target.files[0];
    },

    onFileChangeAdicional(event) {
      this.mantenimiento.sizefotoadicional = event.target.files[0].length;
      this.mantenimiento.fotoadicional = event.target.files[0];
    },

    showOverlay() {
      this.overlayVisible = true;

      setTimeout(() => {
        this.overlayVisible = false;
      }, 3000);
    },

    openModalEquipo: function () {
      this.showmodalinfoequipo = true;
    },

    generarreporte: function (data) {
      var link =
        this.APICONST.PROJECT + "report/reportmtto.php?sticker=" + data;
      window.open(link, "_blank");
    },

    nuevoequipo: async function () {
      this.openModalEquipo();
    },

    ocultarDialogInfoEquipo: function (value) {
      if (!value) {
        this.showmodalinfoequipo = false;
      }
    },

    buscarComputadorxSticker() {
      this.showOverlay();
    },

    getTiposComputadores: async function () {
      try {
        this.overlayVisible = true;
        let self = this;
        await this.axios
          .get("computador.php?dato=gettiposdecomputadores")
          .then(function (response) {
            if (response.status === self.APICONST.RESPONSE_STATUS_OK) {
              self.tiposcomputadores = response.data;
            } else {
              self.UTILS.error(
                self.APICONST.TOAST_ERROR_MESSAGE,
                "Error al gettiposdecomputadores"
              );
            }
          })
          .catch((error) => {
            self.UTILS.error(
              self.APICONST.TOAST_ERROR_MESSAGE,
              self.APICONST.RESPONSE_ERROR_MESSAGE
            );
            self.UTILS.error(error.code, error.message);
          })
          .finally(() => {
            self.overlayVisible = false;
          });
      } catch (e) {
        self.UTILS.warn(
          self.APICONST.TOAST_ALERT_MESSAGE,
          self.APICONST.FRONTEND_EXCEPTION_MESSAGE
        );
        alert("Exception Javascript: " + e);
        console.log(e);
      }
    },

    getTiposPropiedades: async function () {
      try {
        this.overlayVisible = true;
        let self = this;
        await this.axios
          .get("computador.php?dato=gettipospropiedad")
          .then(function (response) {
            if (response.status === self.APICONST.RESPONSE_STATUS_OK) {
              self.tipospropiedades = response.data;
            } else {
              self.UTILS.error(
                self.APICONST.TOAST_ERROR_MESSAGE,
                "Error al gettipospropiedad"
              );
            }
          })
          .catch((error) => {
            self.UTILS.error(
              self.APICONST.TOAST_ERROR_MESSAGE,
              self.APICONST.RESPONSE_ERROR_MESSAGE
            );
            self.UTILS.error(error.code, error.message);
          })
          .finally(() => {
            self.overlayVisible = false;
          });
      } catch (e) {
        self.UTILS.warn(
          self.APICONST.TOAST_ALERT_MESSAGE,
          self.APICONST.FRONTEND_EXCEPTION_MESSAGE
        );
        alert("Exception Javascript: " + e);
        console.log(e);
      }
    },

    getTecnicos: async function () {
      try {
        this.overlayVisible = true;
        let self = this;
        await this.axios
          .get("computador.php?dato=gettecnicos")
          .then(function (response) {
            if (response.status === self.APICONST.RESPONSE_STATUS_OK) {
              self.tecnicos = response.data;
            } else {
              self.UTILS.error(
                self.APICONST.TOAST_ERROR_MESSAGE,
                "Error al gettecnicos"
              );
            }
          })
          .catch((error) => {
            self.UTILS.error(
              self.APICONST.TOAST_ERROR_MESSAGE,
              self.APICONST.RESPONSE_ERROR_MESSAGE
            );
            self.UTILS.error(error.code, error.message);
          })
          .finally(() => {
            self.overlayVisible = false;
          });
      } catch (e) {
        self.UTILS.warn(
          self.APICONST.TOAST_ALERT_MESSAGE,
          self.APICONST.FRONTEND_EXCEPTION_MESSAGE
        );
        alert("Exception Javascript: " + e);
        console.log(e);
      }
    },

    getFuncionarios: async function () {
      try {
        this.overlayVisible = true;
        let self = this;
        await this.axios
          .get("computador.php?dato=getfuncionarios")
          .then(function (response) {
            if (response.status === self.APICONST.RESPONSE_STATUS_OK) {
              self.responsables = response.data;
            } else {
              self.UTILS.error(
                self.APICONST.TOAST_ERROR_MESSAGE,
                "Error al getfuncionarios"
              );
            }
          })
          .catch((error) => {
            self.UTILS.error(
              self.APICONST.TOAST_ERROR_MESSAGE,
              self.APICONST.RESPONSE_ERROR_MESSAGE
            );
            self.UTILS.error(error.code, error.message);
          })
          .finally(() => {
            self.overlayVisible = false;
          });
      } catch (e) {
        self.UTILS.warn(
          self.APICONST.TOAST_ALERT_MESSAGE,
          self.APICONST.FRONTEND_EXCEPTION_MESSAGE
        );
        alert("Exception Javascript: " + e);
        console.log(e);
      }
    },

    guardarMantenimiento: async function () {
      try {
        var mensaje = this.validarRegistrarMtto;
        console.log(mensaje);
        if (mensaje === "") {
          this.overlayVisible = true;

          let formData = new FormData();

          formData.append("serial", this.mantenimiento.computador.serial);
          formData.append("placa", this.mantenimiento.computador.placa);
          formData.append("marca", this.mantenimiento.computador.marca);
          formData.append("modelo", this.mantenimiento.computador.modelo);
          formData.append(
            "procesador",
            this.mantenimiento.computador.procesador
          );
          formData.append("ram", this.mantenimiento.computador.ram);
          formData.append("discoduro", this.mantenimiento.computador.discoduro);
          formData.append("sticker", this.mantenimiento.computador.sticker);
          formData.append("activo", this.mantenimiento.computador.activo);
          formData.append("garantia", this.mantenimiento.computador.garantia);
          formData.append(
            "tipopropiedad",
            this.mantenimiento.computador.tipopropiedad.id
          );
          formData.append(
            "tipocomputador",
            this.mantenimiento.computador.tipocomputador.id
          );
          formData.append("stickermmto", this.mantenimiento.sticker);

          formData.append("tecnico", this.mantenimiento.tecnico.id);
          formData.append("calificacion", this.mantenimiento.calificacion);
          formData.append("funcionario", this.mantenimiento.funcionario.id);
          formData.append("observaciones", this.mantenimiento.observaciones);
          formData.append("impresora", this.mantenimiento.impresora.id);

          let day = this.mantenimiento.fecha.getDate();
          let month = this.mantenimiento.fecha.getMonth() + 1;
          let year = this.mantenimiento.fecha.getFullYear();

          let fecha =
            year +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day);
          formData.append("fecha", fecha);

          var pesomax = this.APICONST.SIZE_MAX_FILE;
          var basemegas = this.APICONST.MEGA_BASE;

          if (
            Math.trunc(this.mantenimiento.fotoantes.size / basemegas) > pesomax
          ) {
            return;
          }
          if (
            Math.trunc(this.mantenimiento.fotodespues.size / basemegas) >
            pesomax
          ) {
            return;
          }
          if (
            Math.trunc(this.mantenimiento.fotosticker.size / basemegas) >
            pesomax
          ) {
            return;
          }
          if (
            Math.trunc(this.mantenimiento.fotoadicional.size / basemegas) >
            pesomax
          ) {
            return;
          }

          formData.append("file", this.selectedFile);

          formData.append("file[]", this.mantenimiento.fotoantes);
          formData.append("file[]", this.mantenimiento.fotodespues);
          formData.append("file[]", this.mantenimiento.fotosticker);
          formData.append("file[]", this.mantenimiento.fotoadicional);
          formData.append("file[]", this.mantenimiento.fotoadicional);

          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

          console.log(isEmpty);
          console.log(data);

          
          formData.append("firma", data);

          let self = this;

          await self
            .axios({
              method: "post",
              url: "computador.php?dato=nuevomantenimiento",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              if (response.status === self.APICONST.RESPONSE_STATUS_OK) {
                self.showmodalinfoequipo = false;
              } else {
                self.typemessage = "error";
                self.showMessage = true;
                self.messageReturn =
                  self.APICONST.TOAST_ERROR_MESSAGE +
                  ": " +
                  self.APICONST.RESPONSE_ERROR_MESSAGE;
              }
            })
            .catch((error) => {
              self.showMessage = true;
              self.messageReturn =
                self.APICONST.TOAST_ERROR_MESSAGE +
                ": " +
                self.APICONST.RESPONSE_ERROR_MESSAGE +
                " : " +
                error.message;
              self.typemessage = "error";
            })
            .finally(() => {
              self.overlayVisible = false;
            });
        } else {
          this.typemessage = "warn";
          this.showMessage = true;
          this.messageReturn = mensaje;
        }
      } catch (e) {

        self.typemessage = "warn";
        self.showMessage = true;
        self.messageReturn = self.APICONST.TOAST_ALERT_MESSAGE + ": " + e;
      }
    },

    undoSignature: function () {
      this.$refs.signaturePad.undoSignature();
    },
  },

  components: {
    NavbarMain3Copy,
    OverlayCustom,
    VueSignaturePad,
    //TableSkeleton,
    //SignaturePad,
  },

  created: function () {
    this.getTiposComputadores();
    this.getTiposPropiedades();
    this.getFuncionarios();
    this.getTecnicos();
    //this.showOverlay();
  },

  computed: {
    validarRegistrarMtto() {
      if (
        this.mantenimiento.fotoantes === null ||
        this.mantenimiento.fotodespues === null ||
        this.mantenimiento.fotosticker === null ||
        this.mantenimiento.fotoadicional === null
      ) {
        return "Las evidencias multimedias están incompletas";
      }
      return "";
    },
  },

  mounted() {
    //this.signaturePad = new SignaturePad(this.$refs.signaturePad);
  },
};
</script>

<style>
.signature-container canvas {
  border: 1px solid black;
}

.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signature-label {
  margin-bottom: 10px;
  text-align: center;
}

.mainreduce {
  transform: scale(0.8);
}

.ochenta {
  transform: scale(0.8);
}

.allwidth {
  width: 100%;
}
.container-computador {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pantalla {
  width: 250px;
  height: 100px;
  border: 5px solid;
  border-color: green;
  border-radius: 10px;
  position: relative;
}
.basevertical {
  width: 80px;
  height: 40px;
  border: 5px solid;
  border-color: green;
  position: relative;
  top: -5px;
}

.basehorizontal {
  width: 250px;
  height: 20px;
  border: 5px solid;
  border-color: green;
  position: relative;
  top: -10px;
}
.container-cpu {
  width: 80px;
  height: 100px;
  position: relative;
  margin-left: 10px;
  perspective: 800px;
}
</style>
