<template>
  <div>
    <NavbarMain3Copy> </NavbarMain3Copy>

    <div class="container text-center mainreduce">
      <div class="row">
        <div class="col-12">
          <InputText
            id="username"
            class="allwidth"
            autocomplete="off"
            v-model="filtersticker"
            placeholder="Digite código de Mantenimiento"
            @keyup.enter="getequipo"
          />
        </div>
      </div>

      <div class="row">
        <MessageText v-show="consultasinresultado" severity="warn" sticky>{{
          mensajesinresultado
        }}</MessageText>
      </div>

      <!-- 
      <div class="row">
        <TableSkeleton
          :visible="overlayVisible"
          @close="overlayVisible = false"
        >
        </TableSkeleton>
      </div>
      -->

    </div>

    <OverlayCustom :visible="overlayVisible" @close="overlayVisible = false">
    </OverlayCustom>

    <DialogModal
      header="Header"
      :visible="showmodalinfoequipo"
      :modal="true"
      :style="{ width: '75vw' }"
      @update:visible="ocultarDialogInfoEquipo"
    >
      <template #header>
        <h5>Información del equipo</h5>
      </template>

      <form>
        <div class="p-fluid grid formgrid">
          <div class="field col-12 md:col-3">
            <label for="serial">Serial</label>
            <InputText
              id="serial"
              :readonly="sololectura"
              placeholder="Serial"
              v-model="mantenimiento.computador.serial"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="placa">Placa</label>
            <InputText
              id="placa"
              :readonly="sololectura"
              placeholder="Placa"
              v-model="mantenimiento.computador.placa"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="activo">Estado equipo</label>
            <InputText
              id="activo"
              :readonly="sololectura"
              placeholder="Activo"
              v-model="mantenimiento.computador.activo"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="garantia">Estado Garantía</label>
            <InputText
              id="garantia"
              :readonly="sololectura"
              placeholder="Garantía"
              v-model="mantenimiento.computador.garantia"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label for="marca">Marca</label>
            <InputText
              id="marca"
              :readonly="sololectura"
              placeholder="Marca"
              v-model="mantenimiento.computador.marca"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="modelo">Modelo</label>
            <InputText
              id="modelo"
              :readonly="sololectura"
              placeholder="Modelo"
              v-model="mantenimiento.computador.modelo"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="procesador">Procesador</label>
            <InputText
              id="procesador"
              :readonly="sololectura"
              placeholder="Procesador"
              v-model="mantenimiento.computador.procesador"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="discoduro">Disco duro</label>
            <InputText
              id="discoduro"
              :readonly="sololectura"
              placeholder="Discoduro"
              v-model="mantenimiento.computador.discoduro"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="ram">Ram</label>
            <InputText
              id="ram"
              :readonly="sololectura"
              placeholder="Ram"
              v-model="mantenimiento.computador.ram"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="sticker">Sticker Inventario</label>
            <InputText
              id="sticker"
              :readonly="sololectura"
              placeholder="Sticker"
              v-model="mantenimiento.computador.sticker"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label for="tipopc">Tipo de computador</label>
            <InputText
              id="tipopc"
              :readonly="sololectura"
              placeholder="Tipo de computador"
              v-model="mantenimiento.computador.tipocomputador.detalle"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="tipopropiedad">Tipo de propiedad</label>
            <InputText
              id="tipopropiedad"
              :readonly="sololectura"
              placeholder="Tipo de propiedad"
              v-model="mantenimiento.computador.tipopropiedad.detalle"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label for="fechamantenimiento">Fecha Mantenimiento</label>
            <InputText
              id="fechamantenimiento"
              :readonly="sololectura"
              placeholder="Fecha Mantenimiento"
              v-model="mantenimiento.fecha"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="tecnico">Responsable Mantenimiento</label>
            <InputText
              id="tecnico"
              :readonly="sololectura"
              placeholder="Tecnico"
              v-model="mantenimiento.tecnico.nombre"
            />
          </div>

          <div class="field col-12 md:col-6">
            <label for="calificacion">Evaluación</label>
            <InputText
              id="calificacion"
              :readonly="sololectura"
              placeholder="Calificación"
              v-model="mantenimiento.calificacion"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="funcionario">Responsable del Equipo</label>
            <InputText
              id="funcionario"
              :readonly="sololectura"
              placeholder="Funcionario"
              v-model="mantenimiento.funcionario.nombre"
            />
          </div>

          <div class="field col-12">
            <label for="observaciones">Observaciones</label>
            <TextArea
              id="observaciones"
              :readonly="sololectura"
              v-model="mantenimiento.observaciones"
              rows="4"
              cols="60"
              placeholder="Observaciones"
            />
          </div>

          <div class="field col-12 md:col-3">
            <ButtonPrime icon="pi pi-file-pdf" severity="danger" aria-label="Cancel" label="Reporte" @click="generarreporte(mantenimiento.sticker)"/>
          </div>
        </div>
      </form>
      <template #footer> </template>
    </DialogModal>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarMain3Copy from "@/components/NavbarMain3Copy.vue";
import OverlayCustom from "@/components/OverlayCustom.vue";
//import TableSkeleton from "@/components/TableSkeleton.vue";

export default {
  name: "QrMantenimientos",
  data() {
    return {
      overlayVisible: false,
      computadoresloading: true,
      showmodalinfoequipo: false,
      computadores: [],
      consultasinresultado: false,
      mensajesinresultado: "",
      sololectura: true,

      filtersticker: "",

      mantenimiento: {
        id: 0,
        sticker: "",
        fecha: "",
        observaciones: "",
        calificacion: 0,
        tecnico: {
          id: 0,
          nombre: "",
          telefono: "",
          correo: "",
        },
        funcionario: {
          id: 0,
          nombre: "",
          cargo: "",
          correo: "",
          telefono: "",
          token: "",
          dependencia: {
            id: 0,
            nombre: "",
            empresa: {
              id: 0,
              nombre: "",
              digito: 0,
              telefono: "",
              telefonoalterno: "",
              correo: "",
              correoalterno: "",
            },
          },
        },
        computador: {
          id: 0,
          serial: "",
          placa: "",
          marca: "",
          modelo: "",
          procesador: "",
          discoduro: "",
          ram: "",
          sticker: "",
          activo: "",
          garantia: "",
          tipopropiedad: {
            id: 0,
            detalle: "",
          },
          tipocomputador: {
            id: 0,
            detalle: "",
          },
        },
        impresora: {
          id: 0,
          serial: "",
          placa: "",
          marca: "",
          modelo: "",
          sticker: "",
          activo: true,
          garantia: 0,
          tipopropiedad: {
            id: 0,
            detalle: "",
          },
          tipoimpresora: {
            id: 0,
            detalle: "",
          },
        },
      },
    };
  },

  methods: {
    showOverlay() {
      this.overlayVisible = true;

      setTimeout(() => {
        this.overlayVisible = false;
      }, 3000);
    },

    openModalEquipo: function (data) {
      this.showmodalinfoequipo = true;
      //console.log(data.mansticker);
      this.mantenimiento = {
        id: data.manid,
        sticker: data.mansticker,
        fecha: data.fecha,
        observaciones: data.observaciones,
        calificacion: data.calificacion,
        tecnico: {
          id: 0,
          nombre: data.tecnombre,
          telefono: "",
          correo: "",
        },
        funcionario: {
          id: 0,
          nombre: data.funnombre,
          cargo: "",
          correo: "",
          telefono: "",
          token: "",
          dependencia: {
            id: 0,
            nombre: "",
            empresa: {
              id: 0,
              nombre: "",
              digito: 0,
              telefono: "",
              telefonoalterno: "",
              correo: "",
              correoalterno: "",
            },
          },
        },
        computador: {
          id: 0,
          serial: data.comserial,
          placa: data.complaca,
          marca: data.commarca,
          modelo: data.commodelo,
          procesador: data.procesador,
          discoduro: data.discoduro,
          ram: data.ram,
          sticker: data.comsticker,
          activo: data.comactivo === 0 ? "INACTIVO" : "ACTIVO",
          garantia: data.comgarantia === 0 ? "SIN GARANTÍA" : "EN GARANTÍA",
          tipopropiedad: {
            id: 0,
            detalle: data.tppdetalle,
          },
          tipocomputador: {
            id: 0,
            detalle: data.tpcdetalle,
          },
        },
        impresora: {
          id: 0,
          serial: "",
          placa: "",
          marca: "",
          modelo: "",
          sticker: "",
          activo: true,
          garantia: 0,
          tipopropiedad: {
            id: 0,
            detalle: "",
          },
          tipoimpresora: {
            id: 0,
            detalle: "",
          },
        },
      };
    },

    generarreporte: function (data) {
      var link =
      this.APICONST.PROJECT +
        "report/reportmtto.php?sticker=" +
        data;
      window.open(link, "_blank");
    },

    getequipo: async function () {
      try {
        this.overlayVisible = true;
        let self = this;
        await this.axios
          .get("computador.php?dato=getequipo&sticker=" + this.filtersticker)
          .then(function (response) {
            if (response.status === self.APICONST.RESPONSE_STATUS_OK) {
              if (response.data.length > 0) {
                self.computadores = response.data[0];
                self.openModalEquipo(self.computadores);
                self.consultasinresultado = false;
              } else {
                self.mensajesinresultado =
                  self.APICONST.TOAST_ALERT_MESSAGE +
                  ": " +
                  self.APICONST.RESPONSE_EMPTY;
                self.computadores = response.data;
                self.consultasinresultado = true;
              }
            } else {
              self.UTILS.error(
                self.APICONST.TOAST_ERROR_MESSAGE,
                "Error al computadores"
              );
            }
          })
          .catch((error) => {
            self.UTILS.error(
              self.APICONST.TOAST_ERROR_MESSAGE,
              self.APICONST.RESPONSE_ERROR_MESSAGE
            );
            self.UTILS.error(error.code, error.message);
          })
          .finally(() => {
            self.overlayVisible = false;
          });
      } catch (e) {
        self.UTILS.warn(
          self.APICONST.TOAST_ALERT_MESSAGE,
          self.APICONST.FRONTEND_EXCEPTION_MESSAGE
        );
        alert("Exception Javascript: " + e);
        console.log(e);
      }
    },

    ocultarDialogInfoEquipo: function (value) {
      if (!value) {
        this.showmodalinfoequipo = false;
      }
    },

    buscarComputadorxSticker() {
      this.showOverlay();
    },
  },

  components: {
    NavbarMain3Copy,
    OverlayCustom,
    //TableSkeleton,
  },

  created: function () {
    //this.showOverlay();
  },

  mounted() {},
};
</script>

<style>
.mainreduce {
  transform: scale(0.8);
}

.allwidth {
  width: 100%;
}
.container-computador {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pantalla {
  width: 250px;
  height: 100px;
  border: 5px solid;
  border-color: green;
  border-radius: 10px;
  position: relative;
}
.basevertical {
  width: 80px;
  height: 40px;
  border: 5px solid;
  border-color: green;
  position: relative;
  top: -5px;
}

.basehorizontal {
  width: 250px;
  height: 20px;
  border: 5px solid;
  border-color: green;
  position: relative;
  top: -10px;
}
.container-cpu {
  width: 80px;
  height: 100px;
  position: relative;
  margin-left: 10px;
  perspective: 800px;
}
</style>
